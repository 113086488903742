import { useEffect, useState } from 'react';

import './style.css'
export default function Loading() {

    const [displayedText, setDisplayedText] = useState('');
    const [finish, setFinish] = useState(false);
    const text = '理论答案查询系统';

    const runHello = (text) => {
        const replyArray = text.split('');
        let index = 0;
        let content = ""
        let typeText = () => {
            const interval = setInterval(() => {
                if (index < replyArray.length) {
                    // 逐个字母地将文本添加到对话对象中
                    content += replyArray[index];
                    setDisplayedText(content);
                    index++;
                } else {
                    // 清除间隔函数

                    clearInterval(interval);
                    setTimeout(() => {
                        setFinish(true)
                    }, 500);



                }
            }, 25); // 设置每个字母显示的间隔时间，单位为毫秒
        }
        typeText()

    }

    useEffect(() => {
        setTimeout(() => {
            runHello(text);
        }, 1000);

    }, []);

    return (
        <div className=' flex flex-col items-center justify-center text-center w-full min-h-screen' >
            <div>


                {finish ? (
                    <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="animate-spin text-center m-auto w-5 h-5"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line x1={12} y1={2} x2={12} y2={6} />
                        <line x1={12} y1={18} x2={12} y2={22} />
                        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76" />
                        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07" />
                        <line x1={2} y1={12} x2={6} y2={12} />
                        <line x1={18} y1={12} x2={22} y2={12} />
                        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24" />
                        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93" />
                    </svg>
                ) :
                    (
                        <div className='m-auto w-5 h-5' ></div>
                    )

                }

                <div className=" flex items-center justify-center mt-4 font-medium text-3xl fade-in-up space-x-1 animate-spin w-full" >
                    <img src="https://p.ampmake.com/fed/m01/asset-1907/image2.0/logo/logo-text-black.019a699a.svg" alt="Logo" className={`w-15 h-full ${finish && 'animate-pulse'}`} />
                    <div className={`${finish && 'animate-pulse'} text-sm `}  >
                        {displayedText}

                    </div>



                </div>
            </div>

        </div>
    )
}