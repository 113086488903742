import React from 'react';
import { SnackbarProvider } from 'notistack';

import ReactDOM from 'react-dom/client';
import './index.css';
import './tailwindcss.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <SnackbarProvider maxSnack={1}         anchorOrigin={{
        vertical: 'top',   // 垂直位置设置为顶部
        horizontal: 'center',  // 水平位置设置为居中
        }}>
            <App />

        </SnackbarProvider>

    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
