import { useState, useEffect } from 'react';
import './App.css';
import { useSnackbar } from 'notistack';
import Loading from './Loading/index.jsx';
function App() {
    const [query, setQuery] = useState(''); // 用于存储输入框的值
    const [questionLists, setQuestionLists] = useState([]); // 用于存储输入框的值
    const [isPageLoaded, setIsPageLoaded] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const handleClick = (msg, variant) => {
        // 在点击时显示通知
        enqueueSnackbar(msg, { variant: variant });
    };

    const getData = () => {
        setLoading(true)

        return fetch(`/api/?s=${query}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .catch(error => {
                setLoading(false)
                console.error('There was a problem with the fetch operation:', error);
                return []; // 可以返回一个空数组避免出错
            });
    };
    const send = async () => {
        // 获取输入框中的内容
        // console.log('查询内容:', query);
        if (!query) {
            setQuestionLists([]);
            handleClick("查询题目不能为空", "warning")
            return null;  // 或者返回其他内容，例如 loading 状态等
        }

        // 获取数据并更新 state
        const data = await getData();
        if (data.length === 0) {
            handleClick("数据库不存在该题目", "error")
            setQuestionLists([]);
            setLoading(false)
            return
        }
        setLoading(false)
        handleClick("查询成功", "success")
        setQuestionLists(data);
        // console.log('更新后的 questionLists:', questionLists);
    };

    useEffect(() => {

        const pageComplete = () => {
            // setIsPageLoaded(true);
            setTimeout(() => {
                setIsPageLoaded(true);
            }, 3000);
        }


        if (document.readyState === 'complete') {
            pageComplete()
        } else {
            const handleLoad = () => pageComplete()
            window.addEventListener('load', handleLoad);

            return () => window.removeEventListener('load', handleLoad);
        }
    }, []);

    if (!isPageLoaded) {
        return <Loading />
    }
    return (
        <>
            <div className=" flex flex-col  w-full h-full px-2">
                <div className="flex flex-col items-center md:mt-40 max-md:mt-14 w-full ">
                    <div className='flex'>
                    <img src="https://p.ampmake.com/fed/m01/asset-1907/image2.0/logo/logo-text-black.019a699a.svg" alt="LI Logo" className="mb-6 w-15 h-7" />
                    <span className='mt-2 m-2 text-sm text-gray-300 font-bold'>理论知识题库</span>

                    </div>
                    
                    <form
                        className="flex rounded-xl overflow-hidden border shadow-lg md:w-1/2 max-md:w-full"
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <input
                            value={query} // 输入框的值绑定到 state
                            onChange={(e) => setQuery(e.target.value)} // 当输入框内容变化时更新 state

                            className="px-4 py-3 w-full border-none"
                            placeholder="输入考题关键字查询答案。"
                        />
                        <button
                            onClick={send}

                            className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 !whitespace-nowrap"
                        >
                            搜索一下
                        </button>
                    </form>
                    <span className='mt-2 text-xs text-gray-300'>请珍惜您的每一场考试，切勿作弊!</span>
                </div>

                {questionLists.length !==0&&(
                    <div className='max-h-[280px] relative overflow-y-auto mt-4 mb-20 border rounded-lg md:w-1/2 max-md:w-full mx-auto  '>
                    
                    {questionLists.map((item, index) => (
                        <div key={index} className='flex flex-col items-center justify-center my-4 px-4 space-y-2 w-full h-full'>
                            <div className='border w-full bg-gray-50/75 rounded-md shadow-md p-4 text-sm space-y-2'>
                                <div className='font-medium'>
                                    题目：{item.question}
                                </div>
                                <div>
                                    <div className='text-green-500 mb-2'>
                                        正确答案：
                                    </div>
                                    <div>
                                    {item.answer}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                )}
                
                 

             

            </div>
            {isLoading&& (
                <div className="absolute inset-0">
                <span
                    data-aria-hidden="true"
                    aria-hidden="true"
                    style={{ display: "none" }}
                />
                <div
                    data-state="open"
                    className="fixed inset-0 z-50 bg-white/50 dark:bg-black/80"
                    style={{ pointerEvents: "auto" }}
                />
                <div className='w-full h-full flex items-center justify-center'>
                    <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="animate-spin text-center m-auto w-5 h-5"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line x1={12} y1={2} x2={12} y2={6} />
                        <line x1={12} y1={18} x2={12} y2={22} />
                        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76" />
                        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07" />
                        <line x1={2} y1={12} x2={6} y2={12} />
                        <line x1={18} y1={12} x2={22} y2={12} />
                        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24" />
                        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93" />
                    </svg>

                </div>
            </div>

            )}

            


        </>

    );
}

export default App;
